import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AboutCover from '../components/aboutCover.js';
import divider from '../../static/assets/images/Main-DottedDivider.png';
import "../components/about.min.css";

const SecondPage = () => {

  const getImages = async () => {
   
  }

  React.useEffect(() => {
    
    // getImages();
    
  }, [])

  // const insta = data.allInstaNode.edges.map((img) => {
  //   return img.node.localFile.childImageSharp.fluid;
  // })
  const insta = []

  return (
    <Layout>
      <SEO title="About" />
      <div className="about wrapper-1080">
        <h2>About</h2>
        <div className="image-divider" style={{ 'minHeight': '12px' }}>
          <img src={divider} />
        </div>
        <div className="flex-container">
          <div className="book-cover">
            <AboutCover />
          </div>
          <div className="about-text">
            <p ><i style={{'fontFamily':'Source Sans Bold'}}>On a Mission to Love</i> was created with Catholic schools and families in mind. Our Lady of Fatima asked us to pray the Rosary every day. This app and its accompanying book is designed to help classrooms and families like yours pray the Rosary every day with great joy and harmony.</p>
            <p>Inspired by the writings of Pope St. John Paul II, <i>On a Mission to Love</i> shares the beautiful truth of God’s plan for humanity. While meditating on the mysteries of the Rosary and these reflections, children come to a deeper understanding of who they are and why God made them.</p>
            <a target='_blank' href='https://etsy.com/shop/onamissiontolove'>Get the Book</a>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default SecondPage
